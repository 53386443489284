import React from 'react';
import './footer.css';
import fb from '../image/fb.png'
import insta from '../image/insta.png'
import linkedin from '../image/linkedin.png'

const socialIcons = [
  {
    icon: "linkedin",
    url: "https://www.linkedin.com/company/modulobytes",
    label: "Follow us on LinkedIn",
    image:linkedin
  },
  {
    icon: "instagram",
    url: "https://www.instagram.com/",
    label: "Visit our Instagram",
    image: insta, 
  },
  {
    icon: "facebook",
    url: "https://www.facebook.com/",
    label: "Follow us on Facebook",
    image: fb
  },
];

function Footer() {
  return (
    <footer>
      <div className="flex justify-around py-4 bg-slate-100 footer_detail">
        <div className="w-1/3 our_value_text">
          <p className="text-2xl font-bold mb-2 text-green-600">Our Values</p>
          <p>
            Our value comes from adding value to our clients, and we are determined to do our work to the best of our abilities.
            We believe in simplicity and quality because software is only worth its mettle if it is simple yet of high quality.
            We offer a wide range of services because our team members bring their expertise and experience from different fields of software development.
            We are always ready to customize our proposals to meet the client's needs. A heart-to-heart conversation is all it takes to get on the same page and reach a win-win solution, so let's have one.
          </p>
        </div>
        <div className="footer_address">
          <p className="font-bold text-2xl mb-2 text-green-600">Get In Touch</p>
          <div className="flex items-center">
            <i className="fa-solid fa-location-dot text-green-600" aria-hidden="true"></i>
            <p className="ml-2">Jaipur, India, 302020</p>
          </div>
          <div className="flex items-center">
            <i className="fa-solid fa-envelope text-green-600" aria-hidden="true"></i>
            <a
              href="mailto:contact@Modulobytes.com"
              className="ml-2 text-blue-600 hover:underline"
              aria-label="Email us at contact@Modulobytes.com"
            >
              contact@Modulobytes.com
            </a>
          </div>
          <div className="flex mt-8 space-x-4">
            {socialIcons.map(({ icon, url, label, image }) => (
              <a
                href={url}
                key={icon}
                className="social-icon"
                aria-label={label}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={image}
                  alt={label}
                  className="w-8 h-8 rounded-full hover:scale-110 transition-transform"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-slate-800">
        <p className="copyright_text text-white py-4 ml-16">
          Copyright © 2022. All Rights Reserved for Modulobytes
        </p>
      </div>
    </footer>
  );
}

export default Footer;
