import React from 'react';
import './career.css'


function CurrentOpenings() {
    return (
        <div className='m-auto justify-center flex py-4'>
            <ul className='ml-10 items-center currentopenings_ul	'>
               
                <li className='my-4 text-2xl text-zinc-500 p-1'> <span className='mr-1.5'>+</span>  Software Developer</li>
                <li className='my-4 text-2xl text-zinc-500 p-1'> <span className='mr-1.5'>+</span>  Front End Developer</li>
                <li className='my-4 text-2xl text-zinc-500 p-1 '> <span className='mr-1.5'>+</span>  Back End Developer</li>
                <li className='my-4 text-2xl text-zinc-500 p-1 '><span className='mr-1.5'>+</span>  Full Stack Developer</li>
                <li className='my-4 text-2xl text-zinc-500 p-1 '><span className='mr-1.5'>+</span> Database Developer</li>
                <li className='my-4 text-2xl text-zinc-500 p-1 '> <span className='mr-1.5'>+</span>  QA Tester</li>

            </ul>
        </div>
    );
}

export default CurrentOpenings;