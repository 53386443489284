import React, { useState } from 'react';

const ContactUs = () => {
  const [formStatus, setFormStatus] = useState('Send');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus('Submitting...');
    const { name, email, message } = formData;
    let conFom = {
      name,
      email,
      message,
    };

    console.log(conFom);

    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setFormStatus('Sent');
  };

  return (
    <div className="container mx-auto mt-10 p-6 max-w-lg bg-white shadow-lg rounded-lg mb-10">
      <div className="text-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-800">Contact Us</h1>
        <p className="text-gray-600">We'd love to hear from you!</p>
      </div>

      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-medium mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="text"
            id="name"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-medium mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="email"
            id="email"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-medium mb-2"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            value={formData.message}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="message"
            required
          ></textarea>
        </div>
        <button
          className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="submit"
        >
          {formStatus}
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
