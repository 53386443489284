import React from 'react';
import Image1 from '../image/blockchain.png';

function ApplicationDevelopment() {
    return (
        <div className="flex justify-center p-4">
            <div className="bg-white shadow-md rounded-lg overflow-hidden max-w-sm">
                <img 
                    src={Image1} 
                    alt="Blockchain Enterprise Development" 
                    className="w-full h-48 object-cover"
                />
                <div className="p-4">
                    <h5 className="text-lg font-semibold mb-2">Blockchain Enterprise Development</h5>
                    <p className="text-gray-600 text-sm">
                        The dawn of Blockchain has opened new and exciting opportunities for everyone. 
                        Let us help you make the most of these opportunities with our Blockchain Enterprise 
                        Development solutions, leveraging the security and transparency that blockchain 
                        technology offers.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ApplicationDevelopment;
