import React from 'react';
import Image1 from '../image/web_app.jpg';
import Image2 from '../image/mobile-api-integration.png';

function MobileDevelopment() {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-6 mb-14">
            
            <div className="bg-white shadow-md rounded-lg overflow-hidden h-auto max-w-md mx-auto">
                <img 
                    src={Image1} 
                    alt="Mobile Solutions" 
                    className="w-full h-48 object-cover"
                />
                <div className="p-4">
                    <h5 className="text-xl font-semibold mb-2 text-gray-800">Mobile Solutions</h5>
                    <p className="text-gray-600 text-sm">
                        Focused on creating complex enterprise-grade applications, our Mobile Solutions 
                        reduce development time while helping you build resilient applications. We specialize 
                        in developing and migrating applications to microservice architecture, making it ideal 
                        for enterprises that need to serve many clients efficiently.
                    </p>
                </div>
            </div>

          
            <div className="bg-white shadow-md rounded-lg overflow-hidden h-auto max-w-md mx-auto">
                <img 
                    src={Image2} 
                    alt="Mobile API Integrations" 
                    className="w-full h-48 object-cover"
                />
                <div className="p-4">
                    <h5 className="text-xl font-semibold mb-2 text-gray-800">Mobile API Integrations</h5>
                    <p className="text-gray-600 text-sm">
                        Stay ahead by adopting complex changes that optimize your processes. Our team can help 
                        bring fundamental and intricate improvements to your systems, ensuring your business 
                        remains competitive and efficient in meeting customer demands.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MobileDevelopment;
