import React from 'react';
import Image1 from '../image/cloud.jpg';
import Image2 from '../image/cloudImg.jpeg';
import Image3 from '../image/cloud.jpg';
import Image4 from '../image/cloudImg.jpeg';

function ApplicationDevelopment() {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-4 mb-14">
        
            <div className="bg-white shadow-md rounded-lg overflow-hidden h-96 flex flex-col">
                <img 
                    src={Image1} 
                    alt="Cloud Implementation Partner" 
                    className="w-full h-40 object-cover"
                />
                <div className="p-4 flex-1">
                    <h5 className="text-lg font-semibold mb-2">Cloud Implementation Partner</h5>
                    <p className="text-gray-600 text-sm">
                        We are happy to be your partner in the journey of implementing your ideas 
                        and products on the cloud. We are adept at implementing projects on major 
                        platforms like AWS, Azure, GC, and Databricks, ensuring efficient and scalable solutions.
                    </p>
                </div>
            </div>

            {/* Cloud Migration */}
            <div className="bg-white shadow-md rounded-lg overflow-hidden h-96 flex flex-col">
                <img 
                    src={Image2} 
                    alt="Cloud Migration" 
                    className="w-full h-40 object-cover"
                />
                <div className="p-4 flex-1">
                    <h5 className="text-lg font-semibold mb-2">Cloud Migration</h5>
                    <p className="text-gray-600 text-sm">
                        Maintaining physical infrastructure can be costly and tedious. We can 
                        help you migrate your existing product to cloud-based solutions, making it 
                        more efficient and ready to scale with the demands of modern businesses.
                    </p>
                </div>
            </div>

            {/* Cloud Transformation */}
            <div className="bg-white shadow-md rounded-lg overflow-hidden h-96 flex flex-col">
                <img 
                    src={Image3} 
                    alt="Cloud Transformation" 
                    className="w-full h-40 object-cover"
                />
                <div className="p-4 flex-1">
                    <h5 className="text-lg font-semibold mb-2">Cloud Transformation</h5>
                    <p className="text-gray-600 text-sm">
                        Transform your existing product to be cloud-compatible with ease. Our experts 
                        ensure seamless adaptation, preserving functionality while enhancing scalability and reliability.
                    </p>
                </div>
            </div>

            {/* Cloud Management */}
            <div className="bg-white shadow-md rounded-lg overflow-hidden h-96 flex flex-col">
                <img 
                    src={Image4} 
                    alt="Cloud Management" 
                    className="w-full h-40 object-cover"
                />
                <div className="p-4 flex-1">
                    <h5 className="text-lg font-semibold mb-2">Cloud Management</h5>
                    <p className="text-gray-600 text-sm">
                        The cloud environment is ever-changing. Entrust us with the round-the-clock 
                        management and maintenance of your cloud infrastructure so you can focus on 
                        innovation and growth.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ApplicationDevelopment;
