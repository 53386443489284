import React from 'react';
import SecrityServicesImg from '../image/security_services.jpg';
import IAMImg from '../image/IAM.png';

function SecurityServices() {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
           
            <div className="bg-white shadow-md rounded-lg overflow-hidden max-w-md mx-auto">
                <img 
                    src={SecrityServicesImg} 
                    alt="Web Security Assessment" 
                    className="w-full h-48 object-cover"
                />
                <div className="p-4">
                    <h5 className="text-xl font-semibold mb-2 text-gray-800">Web Security Assessment</h5>
                    <p className="text-gray-600 text-sm">
                        Knowing where you stand is the first step toward getting where you want to be. 
                        With our experts assessing and testing your product, you can remain confident 
                        in its existing capabilities and understand what needs improvement.
                    </p>
                </div>
            </div>

           
            <div className="bg-white shadow-md rounded-lg overflow-hidden max-w-md mx-auto">
                <img 
                    src={IAMImg} 
                    alt="IAM" 
                    className="w-full h-48 object-cover"
                />
                <div className="p-4">
                    <h5 className="text-xl font-semibold mb-2 text-gray-800">IAM</h5>
                    <p className="text-gray-600 text-sm">
                        Identity Access Management is critical for access-based security. 
                        We provide custom solutions tailored to your security needs that are lightweight. 
                        If you prefer to implement an existing solution, we have you covered.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SecurityServices;
