

import React from 'react';
import Image1 from '../image/microservices.jpg';
import Image2 from '../image/enterprise_transformation.jpg';
import Image3 from '../image/digital_transformation.jpg';

function ApplicationDevelopment() {
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4 mb-14">
          
            <div className="bg-white shadow-md rounded-lg overflow-hidden h-[500px]">
                <img src={Image1} alt="Microservices Development" className="w-full h-48 object-cover mb-9" />
                <div className="p-4">
                    <h5 className="text-lg font-semibold mb-12">Microservices Development</h5>
                    <p className="text-gray-600 text-sm">
                        This is a software development approach focused on creating complex
                        enterprise-grade applications, reducing development time, and building
                        highly resilient applications. We have the expertise required to develop
                        complex applications in microservice architecture or migrate your existing
                        monolithic application into this architecture. Ideal for enterprise
                        applications serving many clients in a short span.
                    </p>
                </div>
            </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden h-[500px]">
                <img src={Image2} alt="Enterprise Transformation" className="w-full h-48 object-cover mb-9" />
                <div className="p-4">
                    <h5 className="text-lg font-semibold mb-12">Enterprise Transformation</h5>
                    <p className="text-gray-600 text-sm">
                        Organizations aiming to stay ahead in customer delivery need to adopt
                        large and complex changes. Our team can help you implement fundamental
                        or complex changes to optimize processes, ensuring you remain ahead of
                        the competition.
                    </p>
                </div>
            </div>
<div className="bg-white shadow-md rounded-lg overflow-hidden h-[500px]">
                <img src={Image3} alt="Digital Transformation" className="w-full h-48 object-cover mb-9" />
                <div className="p-4">
                    <h5 className="text-lg font-semibold mb-12">Digital Transformation</h5>
                    <p className="text-gray-600 text-sm">
                        Organizations have evolving management needs. Our experts help streamline
                        operations through digital transformation, innovating standard procedures
                        to enhance productivity and better serve customers.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ApplicationDevelopment;
