import React from 'react';
import Image1 from '../image/QA.jpeg';


function QualityAssurance() {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
            
            <div className="bg-white shadow-md rounded-lg overflow-hidden max-w-lg h-[400px] mx-auto">
                <img 
                    src={Image1} 
                    alt="Test Automation" 
                    className="w-full h-48 object-cover"
                />
                <div className="p-4">
                    <h5 className="text-xl font-semibold mb-2 text-gray-800">Test Automation</h5>
                    <p className="text-gray-600 text-sm">
                        We all need a product that is robust, and testing plays a major role in ensuring that.
                        Automation of such an important component will not only make the whole process faster 
                        and reliable but also be cost-effective. Think of this as an investment for a better future.
                    </p>
                </div>
            </div>

           
            <div className="bg-white shadow-md rounded-lg overflow-hidden max-w-lg mx-auto h-[400px] ">
                <img 
                    src={Image1} 
                    alt="End to End Testing" 
                    className="w-full h-48 object-cover"
                />
                <div className="p-4">
                    <h5 className="text-xl font-semibold mb-2 text-gray-800">End to End Testing</h5>
                    <p className="text-gray-600 text-sm">
                        This will comprise unit testing, integration testing, regression testing, 
                        system testing, stress testing, and performance testing.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default QualityAssurance;
