

import React from 'react'

function Address() {
  return (
    <div>
      <p>Address</p>
    </div>
  )
}

export default Address
