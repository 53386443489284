import React from 'react';
import Image1 from '../image/iot.png';

function InternetOfThings() {
    return (
        <div className="flex justify-center p-6">
            <div className="bg-white shadow-md rounded-lg overflow-hidden w-full max-w-md">
                <img 
                    src={Image1} 
                    alt="Internet Of Things" 
                    className="w-full h-48 object-cover"
                />
                <div className="p-4">
                    <h5 className="text-xl font-semibold mb-2 text-gray-800">Internet Of Things</h5>
                    <p className="text-gray-600 text-sm">
                        IoT can bring all sci-fi changes to the reality of life. It has applications 
                        ranging from healthcare, agriculture, supply chain, and manufacturing. Our team 
                        of IoT experts can help you innovate and renovate your business by unlocking the 
                        full potential of the Internet and its things.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default InternetOfThings;
