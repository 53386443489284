import React, { useEffect, useRef, useState } from 'react';
import '../header.css';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import './header.css'
// import Logo from '../images/logo.webp'
import Logo from '../image/logo.webp'


function Header() {
    const [serviceBtn, setServiceBtn] = React.useState(null);
    const [aboutBtn, setAboutBtn] = React.useState(null);
    const [careerBtn, setCareerBtn] = React.useState(null);
    const [contactBtn, setContactBtn] = React.useState(null);

    const openService = Boolean(serviceBtn);
    const handleClick = (event) => {
        setServiceBtn(event.currentTarget);
    };
    const handleServiseClose = () => {
        setServiceBtn(null);
    };

    const openAbout = Boolean(aboutBtn);
    const handleAboutClick = (event) => {
        setAboutBtn(event.currentTarget);
    };
    const handleAboutClose = () => {
        setAboutBtn(null);
    };

    const openCareer = Boolean(careerBtn);
    const handleCareerClick = (event) => {
        setCareerBtn(event.currentTarget);
    };
    const handleCareerClose = () => {
        setCareerBtn(null);
    };

    const openContact = Boolean(contactBtn);
    const handleContactClick = (event) => {
        setContactBtn(event.currentTarget);
    };
    const handleContactClose = () => {
        setContactBtn(null);
    }

    /*<------- header scroll functionality start------->*/
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);

    /* handle scroll event********** */
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.pageYOffset > (elTopOffset + elHeight)) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };

    /* *************add/remove scroll event listener********** */
    useEffect(() => {
        var header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height)
        }

        window.addEventListener('scroll', handleScrollEvent);

        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);
    /* <------header scroll functionality end------> */
// useEffect(() => {
// },[window.innerWidth])

    return (
        <>
            <nav className="flex flex-col" style={{ marginTop: sticky.offset }}>
                <div className="header header-responsive">
                    <div id='myHeader' className='header_responsive logo-location h-16 w-full justify-between items-center  pr-20'  >
                        <div className='flex item-center ml-2.5 logo_and_text_onheader'>
                            <img className='logo' src={Logo} alt='logo'/>
                            <Link to="/" className="navbar-logo ml-2.5"> Modulobytes</Link>
                        </div>
                        <div className='flex address_email'>
                            <div className='flex items-center header_address'>
                                <i className="fa-solid fa-location-dot text-green-600"></i>
                                <div className='flex flex-col'>
                                    <Link to='/' className='location-color ml-2 font-bold'>Vikramaditya Marg,
                                        Mansarovar </Link>
                                    <Link to='/' className='location-color ml-2 font-bold'>Jaipur, India, 302020</Link>
                                </div>
                            </div>
                            <div className='flex items-center ml-4 header_email'>
                                <i className="fa-solid fa-envelope text-green-600"></i>
                                <div className='flex flex-col '>
                                    <Link to='/' className='location-color ml-2 font-bold'>Email Address</Link>
                                    <a  href="mailto:contact@Modulobytes.com" className='location-color ml-2 font-bold'> contact@Modulobytes.com</a>
                                </div>
                            </div>     
                        </div>
                    </div>
                </div>
                <div id="sticky-header" className={` ${sticky.isSticky ? ' sticky' : ''}`} ref={headerRef}>
                    <div className='flex items-center menueSection'>
                        <div className='flex item-center logo_on_green_bar'>
                            <img className={sticky.isSticky ? 'logo1 headerStickyTrue' : ' logo1 headerStickyFalse '} src={Logo} alt='logo'/>
                            <Link to="/" className={sticky.isSticky ? 'navbar-logo2 headerStickyTrue' : 'headerStickyFalse'}> Modulobytes</Link>
                        </div>
                        <div className='header_buttons items-center py-3 flex item-center justify-end w-full pr-10 flex-wrap'>
                            <div className='hover:bg-green-800 home-text rounded mx-2.5'>
                                <Link className='underline-removed no-underline' to='/' ><Button>Home</Button></Link>
                            </div>                            
                            <div className='hover:bg-green-800 rounded mx-2.5 '>
                                <Button
                                    id="basic-button"
                                    aria-controls={openService ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openService ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    Services
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={serviceBtn}
                                    open={openService}
                                    onClose={handleServiseClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <Link className='underline-removed' to='/services/application-development' ><MenuItem onClick={handleServiseClose}>Application Development</MenuItem></Link>
                                    <Link className='underline-removed' to='/services/mobile-development' ><MenuItem onClick={handleServiseClose}>Mobile Development</MenuItem></Link>
                                    <Link className='underline-removed' to='/services/cloud-development' ><MenuItem onClick={handleServiseClose}>Cloud Development</MenuItem></Link>
                                    <Link className='underline-removed' to='/services/security-services' ><MenuItem onClick={handleServiseClose}>Security Services</MenuItem></Link>
                                    <Link className='underline-removed' to='/services/blockchain' ><MenuItem onClick={handleServiseClose}>Blockchain/Web 3.0</MenuItem></Link>
                                    <Link className='underline-removed' to='/services/quality-and-assurance' ><MenuItem onClick={handleServiseClose}>Quality and Assurance</MenuItem></Link>
                                    <Link className='underline-removed' to='/services/internet-of-things' ><MenuItem onClick={handleServiseClose}>Internet Of Things</MenuItem></Link>
                                    {/* <Link className='underline-removed' to='/services' ><MenuItem onClick={handleServiseClose}>Marketing Insights</MenuItem></Link> */}
                                    {/* <Link className='underline-removed' to='/services' ><MenuItem onClick={handleServiseClose}>Digital Transformation</MenuItem></Link> */}
                                </Menu>
                            </div>
                            <div className=' hover:bg-green-800 rounded mx-2.5'>
                                <Button
                                    id="basic-button"
                                    aria-controls={openCareer ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openCareer ? 'true' : undefined}
                                    onClick={handleCareerClick}
                                >
                                    Career
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={careerBtn}
                                    open={openCareer}
                                    onClose={handleCareerClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >

                                    {/* <Link className='underline-removed' to='/career/why-Modulobytes'> <MenuItem onClick={handleCareerClose}>Why Modulobytes</MenuItem></Link> */}
                                    <Link className='underline-removed' to='/career/current-openings'><MenuItem onClick={handleCareerClose}>Current Openings</MenuItem></Link>
                                </Menu>
                            </div>
                            <div className='hover:bg-green-800 list-items rounded mx-2.5'>
                                <Button
                                    id="basic-button"
                                    aria-controls={openAbout ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openAbout ? 'true' : undefined}
                                    onClick={handleAboutClick}
                                >
                                    About
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={aboutBtn}
                                    open={openAbout}
                                    onClose={handleAboutClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <Link className='underline-removed' to='about/who-we-are' ><MenuItem onClick={() => { handleAboutClose() }}>Who We Are</MenuItem></Link>
                                    <Link className='underline-removed' to='/about/why-us'> <MenuItem onClick={handleAboutClose}>Why Us</MenuItem></Link>
                                    {/* <Link className='underline-removed' to='about'> <MenuItem onClick={handleAboutClose}>Mission</MenuItem></Link> */}
                                </Menu>
                            </div>
                            <div className=' hover:bg-green-800 rounded mx-2.5'>
                                <Button
                                    id="basic-button"
                                    aria-controls={openContact ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openContact ? 'true' : undefined}
                                    onClick={handleContactClick}
                                >
                                    Contact Us
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={contactBtn}
                                    open={openContact}
                                    onClose={handleContactClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {/* <Link className='underline-removed' to='/contactus/address'><MenuItem onClick={handleContactClose}>Address</MenuItem></Link> */}
                                    {/* <Link className='underline-removed' to='contactus'><MenuItem onClick={handleContactClose}>Email</MenuItem></Link> */}
                                    <Link className='underline-removed' to='contactus'><MenuItem onClick={handleContactClose}>Contact Us</MenuItem></Link>
                                </Menu>
                            </div>
                        </div>                    
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;